






































































































import { defineComponent, inject } from '@vue/composition-api';
import { useActions, useGetters } from 'vuex-composition-helpers';

import VueRouter from 'vue-router';

import { GET_LOGGED_IN, LOGOUT } from '@/store/auth.actions';

export default defineComponent({
  name: 'HomeDrawer',
  props: {
    items: {
      type: Array,
      default: () => []
    },
    itemsWithChildren: {
      type: Array,
      default: () => []
    },
    adminMenuItems: {
      type: Array,
      default: () => []
    }
  },
  setup: () => {
    const actions = useActions([`${LOGOUT}`]);
    const logoutAction = actions[LOGOUT];

    const router = inject<VueRouter>('$router');
    const logout = async () => {
      await logoutAction();
      router?.push({ name: 'LO' });
    };

    const getters = useGetters([GET_LOGGED_IN]);
    const loggedIn = getters[GET_LOGGED_IN];

    return {
      logout,
      loggedIn
    };
  }
});
