var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-app-bar',{attrs:{"id":"home-app-bar","app":"","color":"white","elevation":"2","height":_vm.$vuetify.breakpoint.xs ? 100 : 120,"width":"100vw"}},[_c('div',{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"d-flex"},[_c('router-link',{attrs:{"to":{ name: 'Home' }}},[_c('v-img',{staticClass:"mr-5",attrs:{"src":require("@/assets/ev-logo.png"),"contain":"","max-width":_vm.$vuetify.breakpoint.xs ? '180px' : '240px',"width":"100%","height":"100%"}})],1),_c('router-link',{attrs:{"to":{ name: 'Home' }}},[_c('v-img',{attrs:{"src":require("@/assets/fw-signet.png"),"contain":"","max-width":_vm.$vuetify.breakpoint.xs ? '52px' : '73px',"width":"100%","height":"100%"}})],1)],1),_c('span',{staticClass:"subtitle align-self-center mt-2",style:({'font-size': _vm.$vuetify.breakpoint.xs ? '25px' : '30px'})},[_vm._v("Feuerwehr Everswinkel")])]),_c('v-spacer',{staticClass:"hidden-xs"}),_c('div',{staticClass:"hidden-md-and-down align-self-end"},[_vm._l((_vm.items),function(item,i){return _c('v-btn',{key:item.name + i,attrs:{"min-width":"96","text":"","exact":item.name === 'Start',"to":{ name: item.route }}},[_vm._v(_vm._s(item.name))])}),_vm._l((_vm.itemsWithChildren),function(item,i){return _c('v-menu',{key:item.name + "menu",attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"sub-menu-button align-self-center",attrs:{"text":""}},'v-btn',attrs,false),on),[_vm._v(_vm._s(item.name)),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-menu-down")])],1)]}}],null,true)},[_c('v-list',{staticClass:"nav-list"},_vm._l((item.children),function(childItem,j){return _c('v-list-item',{key:childItem.name,attrs:{"to":childItem.route ? { name: childItem.route } : null,"href":childItem.file ? childItem.file : null,"target":childItem.file ? "_blank" : null}},[(!childItem.children || childItem.children.length === 0)?_c('v-list-item-title',[_vm._v(_vm._s(childItem.name))]):_vm._e(),(childItem.children && childItem.children.length > 0)?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"sub-menu-button align-self-center",attrs:{"text":""}},'v-btn',attrs,false),on),[_vm._v(_vm._s(childItem.name)),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-menu-down")])],1)]}}],null,true)},[_c('v-list',{staticClass:"nav-list"},_vm._l((childItem.children),function(childChildItem,k){return _c('v-list-item',{key:childChildItem.name,attrs:{"to":childChildItem.route ? { name: childChildItem.route, params: childChildItem.params ? { file: childChildItem.params.file, name: childChildItem.name } : undefined } : null,"href":childChildItem.file ? childChildItem.file : null,"target":childChildItem.file ? "_blank" : null}},[_c('v-list-item-title',[_vm._v(_vm._s(childChildItem.name))])],1)}),1)],1):_vm._e()],1)}),1)],1)}),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"sub-menu-button align-self-center",attrs:{"text":""}},'v-btn',attrs,false),on),[_vm._v("Technik"),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-menu-down")])],1)]}}])},[_c('v-list',{staticClass:"nav-list"},[_c('v-list-item',[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"sub-menu-button align-self-center",attrs:{"text":""}},'v-btn',attrs,false),on),[_vm._v("Fahrzeuge"),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-menu-down")])],1)]}}])},[_c('v-list',[_c('v-list-item',{attrs:{"to":{ name: "FZ_EV" }}},[_c('v-list-item-title',[_vm._v("Everswinkel")])],1),_c('v-list-item',{attrs:{"to":{ name: "FZ_AL" }}},[_c('v-list-item-title',[_vm._v("Alverskirchen")])],1)],1)],1)],1)],1)],1),(_vm.loggedIn)?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"align-self-center",attrs:{"text":"","color":"error"}},'v-btn',attrs,false),on),[_vm._v("Admin"),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-menu-down")])],1)]}}],null,false,606528433)},[_c('v-list',{staticClass:"nav-list"},_vm._l((_vm.adminMenuItems),function(adminItem,j){return _c('v-list-item',{key:adminItem.name,attrs:{"color":"error","to":{ name: adminItem.route }}},[_c('v-list-item-title',[_vm._v(_vm._s(adminItem.name))])],1)}),1)],1):_vm._e(),(_vm.loggedIn)?_c('v-btn',{staticClass:"align-self-center px-0",attrs:{"ripple":false,"min-width":"24","text":""},on:{"click":_vm.logout}},[_c('v-icon',[_vm._v("mdi-logout")])],1):_vm._e(),(!_vm.loggedIn)?_c('v-btn',{staticClass:"align-self-center px-0",attrs:{"to":{ name: 'LO' },"ripple":false,"min-width":"24","text":""}},[_c('v-icon',[_vm._v("mdi-login")])],1):_vm._e()],2),_c('v-app-bar-nav-icon',{staticClass:"mr-0 hidden-lg-and-up",on:{"click":function($event){_vm.drawer = !_vm.drawer}}}),_c('home-drawer',{attrs:{"items":_vm.items,"itemsWithChildren":_vm.mobileItemsWithChildren,"adminMenuItems":_vm.adminMenuItems},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }