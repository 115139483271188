














































































import { defineComponent, ref, inject, computed } from '@vue/composition-api';
import CookieLaw from 'vue-cookie-law';

import { Emitter } from 'mitt';

import { Message, MessageEvent } from '@/types/emitter.type';

export default defineComponent({
  name: 'HomeFooter',
  components: {
    CookieLaw
  },
  setup: () => {
    const externalLinks = ref([
      {
        name: 'Gemeinde Everswinkel',
        link: 'http://www.everswinkel.de'
      },
      {
        name: 'Impressum',
        link: '#/impressum'
      },
      {
        name: 'Datenschutz',
        link: '#/datenschutz'
      }
    ]);

    const globalMsg = ref('');
    const showGlobalMsg = ref(false);
    const globalMsgColor = ref('');

    const emitter = inject<Emitter>('$emitter');
    emitter?.on(MessageEvent.Global, (msg?: Message) => {
      if (!msg) {
        return;
      }

      globalMsg.value = msg.text;
      globalMsgColor.value = msg.type;
      showGlobalMsg.value = true;
    });

    const today = new Date();
    const copyRightYear = computed(() => {
      return today.getFullYear();
    });

    return {
      externalLinks,

      globalMsg,
      showGlobalMsg,
      globalMsgColor,

      copyRightYear
    };
  }
});
