




















































































































































import { computed, defineComponent, inject, ref } from '@vue/composition-api';
import { useActions, useGetters } from 'vuex-composition-helpers';

import VueRouter from 'vue-router';

import { GET_LOGGED_IN, LOGOUT } from '@/store/auth.actions';

import HomeDrawer from './Drawer.vue';

export default defineComponent({
  name: 'HomeAppBar',
  components: {
    HomeDrawer
  },
  setup: () => {
    const drawer = ref(false);
    const items = ref([
      {
        name: 'Start',
        route: 'Home'
      }
    ]);

    const itemsWithChildren = ref([
      {
        name: 'Feuerwehr',
        children: [
          {
            name: 'Brandschutzerziehung',
            route: 'BZE'
          },
          {
            name: 'Netzwerk "Zuhause Sicher"',
            file:
              'https://www.zuhause-sicher.de/fileadmin/inhalte/dokumente/Infoblatt_Brandschutz.pdf'
          },
          {
            name: 'Links',
            route: 'LI'
          }
        ]
      },
      {
        name: 'Über uns',
        children: [
          {
            name: 'Leitung der Feuerwehr',
            route: 'LF'
          },
          {
            name: 'Löschzug Everswinkel',
            children: [
              {
                name: 'Info',
                route: 'LZ_EV'
              },
              {
                name: 'Dienstplan',
                // route: 'DP_EV'
                file: 'dienstplan_eve.pdf'
              }
            ]
          },
          {
            name: 'Löschzug Alverskirchen',
            children: [
              {
                name: 'Info',
                route: 'LZ_AV'
              },
              {
                name: 'Dienstplan',
                // route: 'DP_AV'
                file: 'dienstplan_alv.pdf'
              }
            ]
          },
          {
            name: 'Jugendfeuerwehr',
            children: [
              {
                name: 'Info',
                route: 'JF'
              },
              {
                name: 'Ausflüge',
                route: 'JF_AF'
              },
              {
                name: 'Elterninformation',
                route: 'JF_EI'
              }
            ]
          },
          {
            name: 'Ehrenabteilung',
            route: 'EA'
          },
          {
            name: 'TEO-Verbund',
            children: [
              {
                name: 'Einleitung',
                route: 'TEO_EI'
              },
              {
                name: 'Wasserkomponente',
                route: 'TEO_WK'
              },
              {
                name: 'Notstromkomponente',
                route: 'TEO_NK'
              },
              {
                name: 'Kleiderkammer',
                route: 'TEO_KK'
              },
              {
                name: 'Fahrzeugtechnik',
                route: 'TEO_FT'
              },
              {
                name: 'Ausbildung',
                route: 'TEO_AU'
              }
              // {
              //   name: 'Gerätewartung',
              //   route: 'TEO_GW'
              // }
            ]
          },
          {
            name: 'Geschichte Everswinkel',
            children: [
              {
                name: 'Einleitung',
                route: 'HSI'
              },
              {
                name: 'Anfänge',
                route: 'HSE'
              },
              {
                name: 'Im Lauf der Zeit',
                route: 'HST'
              }
            ]
          }
        ]
      },
      {
        name: 'Aktuelles',
        children: [
          {
            name: 'Einsätze',
            route: 'OL'
          },
          {
            name: 'Neuigkeiten',
            route: 'BL'
          },
          {
            name: 'Veranstaltungen',
            children: [
              {
                name: 'Kameradschaftsfest Alverskirchen 2024',
                route: 'VE',
                params: { file: 'kameradschaftsfest2024.pdf' }
              }
            ]
          }
        ]
      }
    ]);

    const adminMenuItems = [
      {
        name: 'Einsatzeingabe',
        route: 'OI'
      },
      {
        name: 'Berichteingabe',
        route: 'RI'
      },
      {
        name: 'Fahrzeuge bearbeiten',
        route: 'FI'
      } // ,
      // {
      //   name: 'Altes Backup wiederherstellen',
      //   route: 'BW'
      // }
    ];

    const actions = useActions([`${LOGOUT}`]);
    const logoutAction = actions[LOGOUT];

    const router = inject<VueRouter>('$router');
    const logout = async () => {
      await logoutAction();
      router?.push({ name: 'LO' });
    };

    const getters = useGetters([GET_LOGGED_IN]);
    const loggedIn = getters[GET_LOGGED_IN];

    const mobileItemsWithChildren = computed(() => {
      return [
        ...itemsWithChildren.value,
        ...[
          {
            name: 'Technik',
            children: [
              {
                name: 'Fahrzeuge Everswinkel',
                route: 'FZ_EV'
              },
              {
                name: 'Fahrzeuge Alverskirchen',
                route: 'FZ_AL'
              }
            ]
          }
        ]
      ];
    });

    return {
      items,
      itemsWithChildren,
      mobileItemsWithChildren,
      drawer,
      logout,
      loggedIn,
      adminMenuItems
    };
  }
});
