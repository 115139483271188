







import { defineComponent } from '@vue/composition-api';

import HomeAppBar from './AppBar.vue';
import HomeView from './View.vue';
import HomeFooter from './Footer.vue';

export default defineComponent({
  name: 'HomeLayout',
  components: {
    HomeAppBar,
    HomeView,
    HomeFooter
  },
  setup: () => {
    return {};
  }
});
